<template>
  <div class="shared-radial-gradient">
    <div class="shared-radial-gradient__border">
      <div class="shared-radial-gradient__content">
        <slot name="content"></slot>
      </div>
    </div>

    <div :class="['shared-radial-gradient__pulse', pulseInClass]"></div>
    <div v-if="isAnimating" :class="['shared-radial-gradient__pulse', pulseOutClass]"></div>
    <div class="shared-radial-gradient__background"></div>
  </div>
</template>

<script setup lang="ts">
import type { ISharedRadialGradientProps } from './SharedRadialGradient.types';

const { getBackgroundColor } = GlobalUtils.CSS;

const props = withDefaults(defineProps<ISharedRadialGradientProps>(), {
  colors: () => ({
    contentBorder: 'rgb(139 140 150)',
    contentShadow: 'rgb(139 140 150)',
    mainBackground: 'rgba(102 101 111 / 15%)',
    mainPulse: 'rgba(102 101 111 / 15%)',
  }),
  isAnimating: false,
});

const parsedColors = computed(() => ({
  contentBorder: getBackgroundColor(props.colors.contentBorder),
  contentShadow: getBackgroundColor(props.colors.contentShadow),
  mainBackground: getBackgroundColor(props.colors.mainBackground),
  mainPulse: getBackgroundColor(props.colors.mainPulse),
}));

const pulseInClass = computed(() => ({
  'pulse-in': props.isAnimating,
}));

const pulseOutClass = computed(() => ({
  'pulse-out': props.isAnimating,
}));
</script>

<style scoped lang="scss">
.shared-radial-gradient {
  --shared-radial-gradient-main-bg: v-bind(parsedColors.mainBackground);
  --shared-radial-gradient-main-pulse: v-bind(parsedColors.mainPulse);
  --shared-radial-gradient-content-border: v-bind(parsedColors.contentBorder);
  --shared-radial-gradient-content-shadow: v-bind(parsedColors.contentShadow);
}
</style>

<style scoped lang="scss" src="./SharedRadialGradient.scss" />
